export enum UsersApi {
  UpdatePassword = '/WebAPI/SetPassword',
  UpdateUserDetails = '/api/v1.0/users',
  GetUserDetails = 'WebAPI/GetUserDetails',
  UserActivity = '/api/v1.0/users/activity',
  ResetUserPassword = '/WebAPI/ResetPassword',
  UpdateSignature = '/api/v2/User/signature',
  GetUserSignature = '/WebAPI/GetUserSignature',
  SendVerificationMail = '/api/v1.0/auth0/verification/resend',
  UpdateNotificationSettings = '/WebApi/RegisterUserNotificationSettings',
  QrveyReport = '/api/v1.0/qrvey/activelinks/:reportId'
}

export enum AdminUsersApi {
  GetUsers = '/api/v2/admin/User/getUsers',
  GetUser = '/api/v2/admin/User/:userId/getUser',
  CreateUser = '/api/v2/admin/User',
  UpdateUser = '/api/v2/admin/User',
  DeleteUsers = '/api/v2/admin/User/batchDelete',
  ChangeUsersStatus = '/api/v2/admin/User/batchChangeStatus/:statusId',
  ResetUserPassword = '/api/v2/admin/User/resetPassword/:userId'
}

export enum IntegrationApi {
  SetBccEmail = '/api/v1.0/users/crmbccemail',
  SetEmailServerToken = '/WebAPI/SaveEmailSyncToken',
  SetSalesforceEmail = '/api/v1.0/crm/prismatic/proxy',
  CrmCredentials = '/api/v1.0/users/crm/credentials',
  RemoveEmailServerToken = '/WebAPI/RevokeEmailSyncAccount',
  GetCrmData = '/api/v1.0/crm/prismatic/proxy'
}

export enum ThirdPartyApi {
  GetSasToken = '/webapi/GetSasToken',
  GetAlgoliaKey = '/api/v1.0/algolia/userkey',
  QrveyToken = '/api/v1.0/qrvey/token',
  QrveyReport = '/api/v1.0/qrvey/activelinks/:reportId'
}
