import moment from 'moment';
import { countries } from 'country-data';

import { RolesNames } from '../../enums';
import {
  FillBgBriefcaseIcon,
  FillBgStarIcon,
  FillBgUserIcon
} from '../../components/sicons';

export { getInitials } from './getInitials';

export const calculatePrecentage = (base: number, of: number) => {
  const calculation = Math.round((base / of) * 100);

  if (!calculation || calculation === Infinity) return 0;

  if (calculation < 10) return `0${calculation}`;

  return calculation;
};

export const getPercentage = (base: number, of: number) =>
  Math.round((base / of) * 100);

export const daysRemaining = (date: string) => {
  const eventdate = moment(date);
  const todaysdate = moment();

  return eventdate.diff(todaysdate, 'days');
};

export const cleanSearchString = (str: string) => {
  if (!str) return '';

  const tempstring = str.replace(/\+/g, ' ');

  return tempstring;
};

export const generateRole = (role: number) => {
  if (!role) return '-';

  return Object.values(RolesNames)?.[role - 1] || '-';
};

export const generateRoleIcon = (role: number) => {
  if (!role) return '-';

  const icons = [
    <FillBgBriefcaseIcon />,
    <FillBgUserIcon />,
    <FillBgStarIcon htmlColor="#572BD3" />
  ];

  return icons?.[role - 1] || '-';
};

export const generateCountryCode = (fullName: string) => {
  if (!fullName) return '-';
  const countryCode = countries.all.find(code => code.name === fullName);

  if (countryCode) return countryCode.alpha2;

  return '-';
};

export const downloadResource = async (
  url: string,
  name: string,
  fallback?: string
) => {
  // First try to download the file by fetching it
  await fetch(url, { method: 'GET' })
    ?.then(res => {
      // This will show as if blob exits on backend side  if it does not download fallback image (default image)
      if (!res.ok) {
        window?.open(fallback, '_blank');
      }

      return res.blob();
    })
    .then(blob => {
      const imageURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = name || 'Download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(() => {
      // If fetch fails, try to open the url in a new tab
      window?.open(url, '_blank');
    });
};
