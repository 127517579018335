import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableRowProps
} from '@material-ui/core';
import TableBodyCells, { STableCell } from '../components/table/cells';
import TableHeader, {
  TableCheckboxStyle,
  TableHeaderProps
} from '../components/table/header';
import SPagination, { SPaginationProps } from '../components/pagination';
import { useStyles } from '../styles';
import { ColumnsType } from '../../../types';
import { TableState } from '../components/table/states';
import { SCheckbox, SCheckboxProps } from '../../scheckbox';

export interface Row<T> extends Omit<TableRowProps, 'onClick'> {
  rowStyleGetter?: (row: T, index?: number) => React.CSSProperties;
  checkbox?: (row: T) => SCheckboxProps;
  onClick?: (row: T) => void;
}

export interface SPaginatedTableProps<T> {
  list: T[];
  columns: ColumnsType;
  pagination: SPaginationProps;
  keyGetter: (element: T) => string | number;
  header?: Omit<TableHeaderProps, 'columns' | 'checkboxFreeSpace'>;
  row?: Row<T>;
  tableStyle?: React.CSSProperties;
  stickyHeader?: boolean;
  showPagination?: boolean;
  isLoading?: boolean;
  searchQuery?: string;
  customEmptyState?: React.ReactNode;
}

const SPaginatedTable = <T,>({
  list,
  columns,
  pagination,
  keyGetter,
  header = {},
  row = {},
  tableStyle,
  stickyHeader = true,
  showPagination = true,
  isLoading = false,
  searchQuery,
  customEmptyState
}: SPaginatedTableProps<T>) => {
  const classes = useStyles();

  return (
    <Box className={classes.contentWrapper}>
      <TableContainer
        className={clsx(classes.tableContainer, classes.grow)}
        style={tableStyle}>
        <Table stickyHeader={stickyHeader} className={classes.table}>
          <TableHeader
            headerClass={classes.tableHeader}
            columns={columns}
            checkboxFreeSpace={!header?.checkbox && !!row?.checkbox}
            {...header}
          />
          <TableBody>
            {list?.map((element, index) => {
              const { rowStyleGetter, checkbox, onClick, style, ...rest } = row;
              return (
                <TableRow
                  hover
                  className={classes.staticTableRow}
                  key={keyGetter(element)}
                  onClick={() => onClick?.(element)}
                  style={{
                    ...rowStyleGetter?.(element, index),
                    ...style
                  }}
                  {...rest}>
                  {checkbox && (
                    <STableCell
                      style={TableCheckboxStyle}
                      onClick={(e: any) => e.stopPropagation()}>
                      <SCheckbox {...checkbox(element)} />
                    </STableCell>
                  )}
                  <TableBodyCells
                    rowData={element}
                    columns={columns}
                    loading={isLoading}
                    searchQuery={searchQuery}
                  />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TableState
          columns={columns}
          loading={isLoading}
          isSearch={Boolean(searchQuery)}
          dataLength={list?.length || 0}
          customEmptyState={customEmptyState}
        />
      </TableContainer>
      {showPagination && <SPagination {...pagination} />}
    </Box>
  );
};

export default SPaginatedTable;
