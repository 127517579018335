import {
  QueryFunctionContext,
  UseInfiniteQueryOptions,
  UseQueryOptions
} from '@tanstack/react-query';
import { AxiosMethods, ContactsApi, GroupsApi } from '../../../../enums';
import { axiosHelper } from '../../../axios-instance';
import {
  Contact,
  GetContactsQuery,
  GetGroupContactsQuery,
  GetGroupsQuery,
  GetRecentContactsQuery,
  GetSalesforceAccountsQuery,
  GetSalesforceCitiesQuery,
  GetSalesforceStatesQuery,
  HubspotContact
} from './types';
import { BaseQueryResponse } from '../../../../interfaces';

export enum ContactsQueryKey {
  CONTACTS = 'contacts',
  GROUPS = 'groups',
  GROUP_CONTACTS = 'groupContacts',
  RECENT_CONTACTS = 'recentContacts',
  SALESFORCE_ACCOUNTS = 'salesforceAccounts',
  SALESFORCE_STATES = 'salesforceStates',
  SALESFORCE_CITIES = 'salesforceCities',
  SALESFORCE_CONTACTS = 'salesforceContacts',
  HUBSPOT_CONTACTS = 'hubspotContacts'
}

const dynamicId = ':groupId';

interface getContactsQueryParams {
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  sortBy: string;
  search: string;
}

export const getContactsQuery = (
  params: getContactsQueryParams
): UseInfiniteQueryOptions<GetContactsQuery> => ({
  queryKey: [ContactsQueryKey.CONTACTS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: ContactsApi.Contacts,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (
    lastPage: GetContactsQuery,
    allPages: GetContactsQuery[]
  ) => {
    const total = allPages?.reduce((acc, cur) => acc + cur?.Result?.length, 0);
    const currentPageNumber = total / params.pageSize;

    return lastPage?.Result?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

interface getGroupsQueryParams {
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  sortBy: string;
  search: string;
}

export const getGroupsQuery = (
  params: getGroupsQueryParams
): UseInfiniteQueryOptions<GetGroupsQuery> => ({
  queryKey: [ContactsQueryKey.GROUPS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: GroupsApi.Groups,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (lastPage: GetGroupsQuery, allPages: GetGroupsQuery[]) => {
    const total = allPages?.reduce((acc, cur) => acc + cur?.Result?.length, 0);
    const currentPageNumber = total / params.pageSize;

    return lastPage?.Result?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

interface getGroupContactsQueryParams {
  groupId: number;
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  sortBy: string;
  search: string;
}

export const getGroupsContactsQuery = (
  params: getGroupContactsQueryParams
): UseInfiniteQueryOptions<GetGroupContactsQuery> => ({
  queryKey: [ContactsQueryKey.GROUP_CONTACTS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: GroupsApi.GroupContacts.replace(
        dynamicId,
        params.groupId.toString()
      ),
      method: AxiosMethods.GET,
      params: {
        pageSize: params.pageSize,
        pageNumber: data.pageParam || params.pageNumber,
        orderBy: params.orderBy,
        sortBy: params.sortBy,
        search: params.search
      }
    }),
  getNextPageParam: (
    lastPage: GetGroupContactsQuery,
    allPages: GetGroupContactsQuery[]
  ) => {
    const total = allPages?.reduce((acc, cur) => acc + cur?.Result?.length, 0);
    const currentPageNumber = total / params.pageSize;

    return lastPage?.Result?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

interface getRecentContactsQueryParams {
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  orderBy: string;
  search: string;
  sampleSize?: number;
}

export const getRecentContacts = (
  params: getRecentContactsQueryParams
): UseInfiniteQueryOptions<GetRecentContactsQuery> => ({
  queryKey: [ContactsQueryKey.RECENT_CONTACTS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: ContactsApi.RecentContacts,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (
    lastPage: GetRecentContactsQuery,
    allPages: GetRecentContactsQuery[]
  ) => {
    const total = allPages?.reduce((acc, cur) => acc + cur?.Result?.length, 0);
    const currentPageNumber = total / params.pageSize;

    return lastPage?.Result?.length < params.pageSize ||
      total === lastPage.Count
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export interface GetSalesfoceAccounts {
  pageSize: number;
  pageNumber: number;
  states: string[];
  cities: string[];
  searchString: string;
}

export const getSalesforceAccounts = (
  params: GetSalesfoceAccounts
): UseInfiniteQueryOptions<GetSalesforceAccountsQuery> => ({
  queryKey: [ContactsQueryKey.SALESFORCE_ACCOUNTS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: ContactsApi.SalesforceAccounts,
      method: AxiosMethods.POST,
      body: {
        ...params,
        pageNumber: data.pageParam || params.pageNumber
      },
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  getNextPageParam: (lastPage, allPages) => {
    const total = allPages.reduce((acc, curr) => acc + curr?.Data?.length, 0);
    const currentPageNumber = total / params.pageSize;

    return lastPage?.Data?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  }
});

export const getSalesforceStates =
  (): UseQueryOptions<GetSalesforceStatesQuery> => ({
    queryKey: [ContactsQueryKey.SALESFORCE_STATES],
    queryFn: () =>
      axiosHelper({
        endpoint: ContactsApi.SalesforceStates,
        method: AxiosMethods.GET,
        baseURL: process.env.ADMIN_API_BASE_URL
      })
  });

export interface GetSalesforceCities {
  pageSize: number;
  pageNumber: number;
  states: string[];
  searchString: string;
}

export const getSalesforceCities = (
  params: GetSalesforceCities
): UseInfiniteQueryOptions<GetSalesforceCitiesQuery> => ({
  queryKey: [ContactsQueryKey.SALESFORCE_CITIES, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: ContactsApi.SalesforceCities,
      method: AxiosMethods.POST,
      body: { ...params, pageNumber: data.pageParam || params.pageNumber },
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  getNextPageParam: (lastPage, allPages) => {
    const total = allPages.reduce((acc, curr) => acc + curr?.Data?.length, 0);
    const currentPageNumber = total / params.pageSize;

    return lastPage?.Data?.length < params?.pageSize
      ? undefined
      : currentPageNumber + 1;
  }
});

export interface GetSalesforceContacts {
  pageSize: number;
  pageNumber: number;
  states: string[];
  cities: string[];
  accounts: string[];
  searchString: string;
}

export const getSalesforceContacts = (
  params: GetSalesforceContacts
): UseInfiniteQueryOptions<BaseQueryResponse<Contact[]>> => ({
  queryKey: [ContactsQueryKey.SALESFORCE_CONTACTS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: ContactsApi.SalesforceContacts,
      method: AxiosMethods.POST,
      body: {
        ...params,
        pageNumber: data.pageParam || params.pageNumber
      },
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  getNextPageParam: (lastPage, allPages) => {
    const total = allPages.reduce((acc, curr) => acc + curr?.Data?.length, 0);
    const currentPageNumber = total / params.pageSize;

    return lastPage?.Data?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  }
});

export interface GetHubspotContacts {
  pageSize: number;
  pageNumber: number;
  searchString: string;
}

export const getHubspotContacts = (
  params: GetHubspotContacts
): UseInfiniteQueryOptions<BaseQueryResponse<HubspotContact[]>> => ({
  queryKey: [ContactsQueryKey.HUBSPOT_CONTACTS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: ContactsApi.HubspotContacts,
      method: AxiosMethods.POST,
      body: {
        ...params,
        pageNumber: data.pageParam || params.pageNumber
      },
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  getNextPageParam: (lastPage, allPages) => {
    const total = allPages.reduce((acc, curr) => acc + curr?.Data?.length, 0);
    const currentPageNumber = total / params.pageSize;

    return lastPage?.Data?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  }
});
