import React from 'react';
import { Box, Button, Divider, withStyles } from '@material-ui/core';

interface NavigationItem {
  label: string;
  startIcon: React.ReactNode;
  onClick?: () => void;
}

interface FloatingNavigationProps {
  items: NavigationItem[];
}

const FloatingNavigationWrapper = withStyles(() => ({
  root: {
    position: 'absolute',
    bottom: 10,
    left: '50%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    transform: 'translateX(-50%)',
    backgroundColor: '#01294B',
    borderRadius: 50
  }
}))(Box);

export const FloatingNavigation = ({ items }: FloatingNavigationProps) => (
  <FloatingNavigationWrapper>
    {items.map((item, index) => (
      <Box key={item.label} display="flex">
        <Button
          style={{
            color: '#fff'
          }}
          startIcon={item.startIcon}
          key={item.label}
          onClick={item.onClick}>
          {item.label}
        </Button>
        {index + 1 < items?.length && (
          <Divider
            style={{ backgroundColor: '#fff' }}
            flexItem
            orientation="vertical"
          />
        )}
      </Box>
    ))}
  </FloatingNavigationWrapper>
);
