import { TableCell, withStyles } from '@material-ui/core';

const TableHeaderCell = withStyles(() => ({
  root: {
    color: '#696974',
    // Temporary fix for MUI styles issue not being properly applied in MUI v5 on digital showroom project
    // requires MUI update to V5 and style refactoring (removing makeStyles)
    padding: '6px',
    // padding: spacing(2),
    borderBottom: 'none',
    width: 'max-content',

    '&:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px'
    },

    '&:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px'
    },

    '& p': {
      display: 'inline',
      fontWeight: 700,
      fontSize: '0.875rem',
      color: '#696974'
    },
    '& button': {
      display: 'inline',
      padding: '5px',
      margin: '0px',

      '& svg': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    }
  }
}))(TableCell);

export default TableHeaderCell;
