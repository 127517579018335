import { Header } from '@setvi/shared/components/sdrawer-content/components/header';
import { DrawerContent } from '@setvi/shared/enums/common';
import { SSearchInput } from '@setvi/shared/components/sforms/ssearch';
import { TContact } from '@setvi/shared/components/sdrawer-content/components/contact';
import { Box } from '@material-ui/core';
import { SEmptyState } from '@setvi/shared/components';
import { ContactsLoader } from '@setvi/shared/components/sdrawer-content/components/contact/loader';
import { Subheader } from '../../../components/subheader';
import { Recipient } from '../../../../../interfaces';
import { AddressBook } from '../../../components/contact/address-book';
import { useHubspotContacts } from './useHubspotContacts';

interface CrmHubspotProps {
  selectedContacts?: Recipient[];
  modifyRecipientList: (contact: TContact) => void;
  setShowContent: (content: DrawerContent | null) => void;
  handleClose: () => void;
}

export const CrmHubspot = ({
  selectedContacts,
  modifyRecipientList,
  setShowContent,
  handleClose
}: CrmHubspotProps) => {
  const { searchTerm, setSearchTerm, contacts, fetchMoreContacts, isLoading } =
    useHubspotContacts();

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      position="relative"
      overflow="hidden">
      <Header title="Recipients" handleClose={handleClose} />
      <Subheader
        handleBack={() => setShowContent(DrawerContent.contactsNavigation)}
        text="CRM Contacts"
      />
      <Box p={2} mb={1}>
        <SSearchInput defaultValue={searchTerm} onChange={setSearchTerm} />
      </Box>
      <Box overflow="auto" style={{ paddingBottom: '60px' }}>
        <AddressBook
          contacts={contacts}
          selectedContacts={selectedContacts}
          fetchMore={fetchMoreContacts}
          onContactClick={modifyRecipientList}
          sortingParameter="Email">
          <>
            {isLoading && <ContactsLoader />}
            {!isLoading && contacts?.length === 0 && (
              <SEmptyState
                imageSrc="/images/no-contacts-2.png"
                subTitle="No contacts available"
              />
            )}
          </>
        </AddressBook>
      </Box>
    </Box>
  );
};
