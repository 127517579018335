import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { getHubspotContacts } from '../../../../../services';

const CONTACTS_LIMIT = 20;
export const useHubspotContacts = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const queryVariables = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: CONTACTS_LIMIT,
      searchString: searchTerm
    }),
    [searchTerm]
  );

  const {
    data: contacts,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(getHubspotContacts(queryVariables));

  const fetchMoreContacts = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    searchTerm,
    setSearchTerm,
    isLoading: isLoading || isFetching,
    contacts: contacts?.pages.flatMap(page => page.Data) || [],
    fetchMoreContacts
  };
};
