import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { useState } from 'react';
import { Header } from '@setvi/shared/components/sdrawer-content/components/header';
import { DrawerContent } from '@setvi/shared/enums/common';
import { SSearchInput } from '@setvi/shared/components/sforms/ssearch';
import { TContact } from '@setvi/shared/components/sdrawer-content/components/contact';
import { Box } from '@material-ui/core';
import { SEmptyState } from '@setvi/shared/components';
import { ContactsLoader } from '@setvi/shared/components/sdrawer-content/components/contact/loader';
import { Subheader } from '../../../components/subheader';
import { Recipient } from '../../../../../interfaces';
import {
  Account as AccountType,
  City as CityType,
  State as StateType
} from '../../../../../services/react-query/query/contacts/types';
import { AddressBook } from '../../../components/contact/address-book';
import { useCrmContacts } from './useCrmContacts';
import { AccountSelect } from './account-select';
import { FloatingNavigation } from '../../../components/floating-navigation';
import { CitySelect } from './city-select';
import { StateSelect } from './state-select';
import {
  DrawerDialog,
  DrawerDialogContainer
} from '../../../components/dialog';

interface CrmAccountsProps {
  selectedContacts?: Recipient[];
  modifyRecipientList: (contact: TContact) => void;
  setShowContent: (content: DrawerContent | null) => void;
  handleClose: () => void;
}

enum SliderContent {
  State = 'State',
  City = 'City',
  Account = 'Account'
}

export const CrmAccounts = ({
  selectedContacts,
  modifyRecipientList,
  setShowContent,
  handleClose
}: CrmAccountsProps) => {
  const [openedSlider, setOpenedSlider] =
    useState<keyof typeof SliderContent>(undefined);
  const [selectedAccounts, setSelectedAccounts] = useState<AccountType[]>([]);
  const [selectedStateCodes, setSelectedStateCodes] = useState<
    StateType['StateCode'][]
  >([]);
  const [selectedCities, setSelectedCities] = useState<CityType[]>([]);

  const { contacts, fetchMoreContacts, isLoading, setSearchTerm, searchTerm } =
    useCrmContacts({
      accountIds: selectedAccounts.map(account => account.Id),
      cities: selectedCities,
      states: selectedStateCodes
    });

  const handleStateSelect = (stateList: StateType['StateCode'][]) => {
    setSelectedStateCodes(stateList);
    setSelectedCities([]);
    setSelectedAccounts([]);
    setOpenedSlider(undefined);
  };

  const handleCitySelect = (cityList: CityType[]) => {
    setSelectedCities(cityList);
    setOpenedSlider(undefined);
  };

  const handleAccountSelect = (accountList: AccountType[]) => {
    setSelectedAccounts(accountList);
    setOpenedSlider(undefined);
  };

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      position="relative"
      overflow="hidden">
      <Header title="Recipients" handleClose={handleClose} />
      <Subheader
        handleBack={() => setShowContent(DrawerContent.contactsNavigation)}
        text="CRM Contacts"
      />
      <Box p={2} mb={1}>
        <SSearchInput defaultValue={searchTerm} onChange={setSearchTerm} />
      </Box>
      <Box overflow="auto" style={{ paddingBottom: '60px' }}>
        <AddressBook
          contacts={contacts}
          selectedContacts={selectedContacts}
          fetchMore={fetchMoreContacts}
          onContactClick={modifyRecipientList}>
          <>
            {isLoading && <ContactsLoader />}
            {!isLoading && contacts?.length === 0 && (
              <SEmptyState
                imageSrc="/images/no-contacts-2.png"
                subTitle="No contacts available"
              />
            )}
          </>
        </AddressBook>
      </Box>
      <FloatingNavigation
        items={[
          {
            label: 'State',
            startIcon: <MapOutlinedIcon />,
            onClick: () => setOpenedSlider('State')
          },
          {
            label: 'City',
            startIcon: <PinDropOutlinedIcon />,
            onClick: () => setOpenedSlider('City')
          },
          {
            label: 'Accounts',
            startIcon: <PermIdentityIcon />,
            onClick: () => setOpenedSlider('Account')
          }
        ]}
      />
      {Boolean(openedSlider) && (
        <DrawerDialogContainer onClick={() => setOpenedSlider(undefined)}>
          <DrawerDialog onClick={e => e.stopPropagation()}>
            {openedSlider === 'State' && (
              <StateSelect
                selectedStates={selectedStateCodes}
                setSelectedStateCodes={handleStateSelect}
                handleClose={() => setOpenedSlider(undefined)}
              />
            )}
            {openedSlider === 'City' && (
              <CitySelect
                selectedStateCodes={selectedStateCodes}
                selectedCities={selectedCities}
                setSelectedCities={handleCitySelect}
                handleClose={() => setOpenedSlider(undefined)}
              />
            )}
            {openedSlider === 'Account' && (
              <AccountSelect
                selectedAccounts={selectedAccounts}
                selectedCities={selectedCities}
                selectedStateCodes={selectedStateCodes}
                setSelectedAccounts={handleAccountSelect}
                handleClose={() => setOpenedSlider(undefined)}
              />
            )}
          </DrawerDialog>
        </DrawerDialogContainer>
      )}
    </Box>
  );
};
