import { Box, Button, ButtonGroup } from '@material-ui/core';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

import {
  SelectDetailsButton,
  SelectMenuHeader,
  SSelectDropdown
} from '@setvi/shared/components';

import { BaseSelectProps, SOption } from '../types';
import { useSingleSelect } from './hook';

export interface SelectProps extends BaseSelectProps {
  onChange: (selected: SOption) => void;
  renderTitle?: (title: string) => JSX.Element;

  noBorder?: boolean;
  defaultOption?: SOption;
  checkIconPosition?: 'start' | 'end';
}

const SingleSelect = ({
  width,
  label = '',
  search = true,
  noBorder = false,
  renderTitle,
  options = [],
  placeholder = '',
  headerTitle,
  clearButton = false,
  defaultOption,
  checkIconPosition = 'start',

  onChange
}: SelectProps) => {
  const {
    data,
    anchor,
    selected,

    setData,
    setAnchor,
    setSelected,
    handleSelect,
    handleSearch
  } = useSingleSelect({
    options,
    defaultOption,

    onChange
  });

  return (
    <SSelectDropdown
      width={width}
      placement="bottom"
      anchor={
        <SelectDetailsButton
          width={width}
          label={label}
          open={Boolean(anchor)}
          title={selected?.label || selected?.value?.toString() || placeholder}
          titleHighligted={false}
          renderTitle={renderTitle}
          noBorder={noBorder}
          onClick={e => setAnchor(e.currentTarget)}
        />
      }
      anchorElement={anchor}
      onClose={() => setAnchor(null)}
      content={
        <Box>
          <SelectMenuHeader
            search={search}
            title={headerTitle}
            clearButton={clearButton}
            handleChange={handleSearch}
            handleClear={() => {
              setSelected(null);
              setData(options);
            }}
          />

          <ButtonGroup
            orientation="vertical"
            color="primary"
            style={{
              display: 'flex'
            }}>
            {data?.length > 0 ? (
              data?.map(option => (
                <Button
                  fullWidth
                  style={{
                    display: 'flex',
                    justifyContent: 'start'
                  }}
                  startIcon={
                    checkIconPosition === 'start' && (
                      <CheckRoundedIcon
                        htmlColor={
                          selected?.value === option?.value ? '#2196F3' : '#fff'
                        }
                      />
                    )
                  }
                  endIcon={
                    checkIconPosition === 'end' && (
                      <CheckRoundedIcon
                        htmlColor={
                          selected?.value === option?.value ? '#2196F3' : '#fff'
                        }
                      />
                    )
                  }
                  key={option.id}
                  variant="text"
                  onClick={() => handleSelect(option)}
                  color="default"
                  disabled={option.disabled}>
                  {option?.htmlElement || option.value}
                </Button>
              ))
            ) : (
              <>No Results Found...</>
            )}
          </ButtonGroup>
        </Box>
      }
    />
  );
};

export default SingleSelect;
