import { useMemo } from 'react';
import { Box } from '@material-ui/core';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import {
  hasAtLeastOneNumber,
  hasAtLeastOneUpperCase,
  hasAtLeastOneSpecialCharacter
} from '../../utils';
import SText from '../../../sui/stext';

enum COLORS {
  GREEN = '#23A566',
  GREY = '#D5D5DC',
  BLACK = '#696974',
  LIGHT_GREY = '#B5B5BE'
}

const SPasswordInstructions = ({ password }: { password: string }) => {
  const instructions = useMemo(
    () => [
      {
        done: password.length >= 8,
        text: 'Minimum 8 characters in length'
      },
      {
        done: hasAtLeastOneNumber(password),
        text: 'At least one Number(0-9)'
      },
      {
        done: hasAtLeastOneUpperCase(password),
        text: 'At least one upper case letter (A-Z)'
      },
      {
        done: hasAtLeastOneSpecialCharacter(password),
        text: 'At least one special character ($#%&{}!)'
      }
    ],
    [password]
  );

  return (
    <Box display="flex" flexDirection="column" gridGap={12}>
      {instructions.map(({ done, text }) => (
        <Box key={text} display="flex" alignItems="center" gridGap={8}>
          <CheckCircleRoundedIcon
            fontSize="small"
            htmlColor={done ? COLORS.GREEN : COLORS.GREY}
          />
          <SText
            title={text}
            fontColor={done ? COLORS.BLACK : COLORS.LIGHT_GREY}
          />
        </Box>
      ))}
    </Box>
  );
};

export default SPasswordInstructions;
