import { CSSProperties, memo } from 'react';
import {
  Box,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import { useStyles } from '@setvi/shared/components/s-table/styles/TableStyles';
import { TableHeaderCell } from '@setvi/shared/components/s-table/styles';
import { SortType } from '@setvi/shared/enums';
import { ColumnsType } from '@setvi/shared/types';
import { SCheckbox, SCheckboxProps } from '../../../../scheckbox';
import { SortIcon } from '../../../../sicons';

export interface TableHeaderProps {
  checkbox?: SCheckboxProps;
  /** This prop is used to add free space(checkbox width) instead of a checkbox to move the first column. In most cases it is used when the header does not have a checkbox and the table does. */
  checkboxFreeSpace?: boolean;
  sort?: {
    sortBy: string;
    orderBy: SortType;
    onChange: (sortBy: string) => void;
  };
  columns: ColumnsType;
  headerClass?: string;
}

const checkboxWidth = 40;

export const TableCheckboxStyle: CSSProperties = {
  width: checkboxWidth,
  maxWidth: checkboxWidth,
  minWidth: checkboxWidth,
  textAlign: 'center'
};

export const STableHead = withStyles({
  root: {
    backgroundColor: '#FAFAFB'
  }
})(TableHead);

export const STableHeaderCell = withStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.down(1500)]: {
      minWidth: ({ minWidth }: any) => minWidth || 150
    },
    [breakpoints.down(1810)]: {
      minWidth: ({ minWidth }: any) => minWidth || 180
    }
  }
}))(TableHeaderCell);

const TableHeader = ({
  checkboxFreeSpace,
  checkbox,
  sort,
  columns,
  headerClass
}: TableHeaderProps) => {
  const classes = useStyles();

  return (
    <STableHead className={headerClass}>
      <TableRow>
        {checkboxFreeSpace && <TableHeaderCell style={TableCheckboxStyle} />}
        {checkbox && !checkboxFreeSpace && (
          <TableHeaderCell style={TableCheckboxStyle}>
            <SCheckbox {...checkbox} />
          </TableHeaderCell>
        )}
        {columns?.map(column => {
          const isSorted = sort?.sortBy === column?.sortBy;

          return (
            <STableHeaderCell
              key={column.id}
              align={column.align}
              minWidth={column.minWidth}
              style={{
                width: column?.width,
                textAlign: column?.align
              }}>
              <Box
                className={column.sortBy && classes.sortable}
                style={{
                  margin: column?.align === 'center' ? 'auto' : 'unset'
                }}
                onClick={() =>
                  column.sortBy && sort?.onChange?.(column.sortBy)
                }>
                {column?.sortBy && (
                  <TableSortLabel
                    className={classes.sortIconWraper}
                    active={isSorted}
                    IconComponent={SortIcon}
                    direction={isSorted ? sort?.orderBy : SortType.ASC}>
                    <Typography noWrap>
                      {column?.label || ''}
                      {column?.required && (
                        <span className={classes.required}>*</span>
                      )}
                    </Typography>
                  </TableSortLabel>
                )}
                {!column?.sortBy && (
                  <Typography noWrap>
                    {column?.label || ''}
                    {column?.required && (
                      <span className={classes.required}>*</span>
                    )}
                  </Typography>
                )}
              </Box>
            </STableHeaderCell>
          );
        })}
      </TableRow>
    </STableHead>
  );
};

export default memo(TableHeader);
