import { AxiosMethods, RFQApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

const body = {
  topK: 3,
  threshold: 0.8
};

const ApiKey = '64c5ca85-6d25-4efe-97c0-33d9a4c080e1';
const headers = {
  Authorization: `ApiKey ${ApiKey}`,
  'Content-Type': 'application/json',
  Accept: 'text/plain'
};

export const getProductsByUrl = () => ({
  mutationFn: (url: string) =>
    axiosHelper({
      endpoint: RFQApi.GetProductsByUrl,
      method: AxiosMethods.POST,
      baseURL: process.env.AI_BASE_URL,
      headers,
      body: {
        url,
        type: 'Standard',
        ...body
      }
    })
});

export const getProductsByText = () => ({
  mutationFn: (text: string) =>
    axiosHelper({
      endpoint: RFQApi.GetProductsByText,
      method: AxiosMethods.POST,
      baseURL: process.env.AI_BASE_URL,
      headers,
      body: {
        text,
        ...body
      }
    })
});
