import {
  Checkbox as MUICheckbox,
  CheckboxProps,
  Tooltip,
  makeStyles,
  Theme
} from '@material-ui/core';
import clsx from 'clsx';
import { CheckboxFilled, CheckboxOutline } from '../sicons';
import { TextColor } from '../../enums';

const useStyles = makeStyles<Theme, { strokeColor: string }>({
  checkbox: {
    borderRadius: 4,
    padding: '0 !important',
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    '& .MuiIconButton-label svg rect': {
      stroke: ({ strokeColor }) => strokeColor
    },
    '& .MuiIconButton-label:has(input:checked) svg rect': {
      stroke: 'transparent'
    },
    '& .Mui-disabled': {
      opacity: 0.5,
      border: '1px solid red'
    }
  }
});

export interface SCheckboxProps extends CheckboxProps {
  tooltip?: string;
  disableTooltip?: boolean;
  strokeColor?: string;
}

export const SCheckbox = ({
  tooltip,
  disableTooltip = true,
  color = 'default',
  strokeColor = TextColor.LightGrey,
  ...rest
}: SCheckboxProps) => {
  const classes = useStyles({ strokeColor });

  if (!disableTooltip || tooltip)
    return (
      <Tooltip title={tooltip || ''} arrow>
        <span>
          <MUICheckbox
            color="default"
            icon={<CheckboxOutline />}
            checkedIcon={<CheckboxFilled />}
            className={clsx(classes.checkbox, rest.className)}
            {...rest}
          />
        </span>
      </Tooltip>
    );

  return (
    <MUICheckbox
      color="default"
      icon={<CheckboxOutline />}
      checkedIcon={<CheckboxFilled />}
      className={clsx(classes.checkbox, rest.className)}
      {...rest}
    />
  );
};
